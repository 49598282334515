.homeFace {
  z-index: 1;
  width: 100vw;
  position: relative;
  overflow: hidden;
  user-drag: none;
  user-select: none;

  img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
