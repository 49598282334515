.skillLogoContainer .img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* LANGUAGES */

.typescript {
  background: url(../assets/typescript.png);
}
.next {
  background: url(../assets/next.svg);
}
.react {
  background: url(../assets/reactLogo.png);
}
.javascript {
  background: url(../assets/javascript.png);
}
.node {
  background: url(../assets/node.png);
}
.html {
  background: url(../assets/html.png);
}
.css {
  background: url(../assets/css.png);
}
.express {
  background: url(../assets/express.png);
}
.php {
  background: url(../assets/php.png);
}
.sql {
  background: url(../assets/sql.png);
}
.python {
  background: url(../assets/python.png);
}
.java {
  background: url(../assets/java.png);
}
.csharp {
  background: url(../assets/csharp.png);
}
.dotnet {
  background: url(../assets/dotnet.png);
}
