* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}
*::before,
*::after {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
html {
  scrollbar-width: none !important;
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
  scroll-behavior: smooth !important;
}
html::-webkit-scrollbar-track {
  display: none !important;
}
html::-webkit-scrollbar {
  display: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

/* ### CONTACT ###   */

.contact {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  /* background-color: black; */
}

@media screen and (max-width: 800px) {
  .contact {
    flex-direction: column;
  }
}

.formImg {
  /* display: none; */
  width: 50%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
@media screen and (max-width: 600px) {
  .formImg {
    /* display: block; */
    width: 50%;
  }
  Form {
    width: 50%;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* margin-bottom: 60px; */
}

.flexRow {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 500px) {
  .flexRow {
    flex-direction: column;
  }
  .flexRow .button {
    margin: 5px 0;
  }
}

Form {
  /* border: 1px solid lightgray; */
  width: 90vw;
  max-width: 500px;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #000; */
}

/* @media screen and (max-width: 500px) {
  Form{
    width: 50%;
  }
} */

/* Form textarea {
  background-color: transparent;
  margin-top: 5px;
  height: 100px;
  font-size: 20px;
  padding: 5px;
} */
/* 
Form input,
Form textarea {
  font-size: 20px;
  padding: 10px;
  border: none;
  border: #000 1px solid;
  border-radius: 5px;
}
Form textarea {
  margin-top: 5px;
}
Form textarea::placeholder,
Form input::placeholder {
  font-size: 1.2rem;
}
Form div {

  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin: 5px 0;
}

Form label {
  color: var(--primary);
} */

.submit {
  padding: 10px 20px;
  margin: 10px;
  border: #000 2px solid;
  border-radius: 5px;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 1rem;
}
/* Button */

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 200px;
  /* border: 2px solid rgb(53, 132, 93); */
  border: 2px solid #fff;
  border-radius: 5px;
  /* backdrop-filter: blur(10px); */
  margin: 20px 5px;
  color: var(--primaryLight);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 15px;
  letter-spacing: 1.5px;
  overflow: hidden;
}
.blur {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
@supports (
  (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) or
    (-moz-backdrop-filter: blur(10px)) or (-o-backdrop-filter: blur(10px)) or
    (-ms-backdrop-filter: blur(10px))
) {
  .blur {
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: transparent !important;
  }
}
.brightness80 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
@supports (
  (-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px)) or
    (-moz-backdrop-filter: blur(15px)) or (-o-backdrop-filter: blur(15px)) or
    (-ms-backdrop-filter: blur(15px))
) {
  .blur {
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    -ms-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    /* background-color: transparent !important; */
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}

@supports (
  (-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px)) or
    (-moz-backdrop-filter: blur(15px)) or (-o-backdrop-filter: blur(15px)) or
    (-ms-backdrop-filter: blur(15px))
) {
  .blackBlur {
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    -ms-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: var(--primary) !important;
    /* background-color: rgb(61, 155, 37, 0.5) !important; */
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
}

a {
  /* color: rgb(53, 132, 93); */
  color: #fff;
  text-decoration: none;

  /* letter-spacing: 1px; */
}

.button {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.button a,
.button span {
  position: relative;
  left: 0;
  transition: all 0.35s ease-Out;
}

#dub-arrow {
  width: 100%;
  height: 100%;
  /* background: rgb(53, 132, 93); */
  /* backdrop-filter: blur(10px); */
  left: -200px;
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-Out;
  bottom: 0;
}

.button img {
  width: 30px;
  height: auto;
}
.formButton {
  background-color: transparent !important;
  border: rgb(53, 132, 93) 2px solid;
}
.formButton img {
  margin-top: 10px;
}
.formButton:hover {
  border-color: #000;
}

.button.greenColor {
  border-color: rgb(20, 49, 13);
}

.button.greenColor a,
.button.greenColor span {
  color: rgb(20, 49, 13);
}
.button.blue:hover {
  border-color: #1da1f2;
}
.button.green:hover {
  border-color: #000 !important;
  background-color: rgb(53, 132, 93);
}
.button:hover #dub-arrow {
  left: 0;
}

.button:hover a,
.button:hover span {
  left: 150px;
}

@media screen and (min-width: 1000px) {
  h1 {
    font-size: 2.2em;
  }
  #container {
    width: 50%;
  }
}
