:root {
  --primary: #0e76a8;
  --primaryLight: #55b8ff;
  --secondary: #ffffff;
  --black: #000;
  --text: #7a7a7a;
}

/* ### HOME ### */
.primaryColor {
  color: var(--primary);
}
body {
  background: white;
}

.home {
  width: 90vw;
  height: auto;
  /* margin-top: 65px; */
  display: flex;
  color: black;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 20px;
  justify-items: center !important;
}
@media (max-width: 650px) {
  .home {
    width: 95vw;
  }
}

.homePage {
  display: flex;
  flex-direction: column-reverse;
  /* margin-top: 32px; */
  margin-bottom: 20px;
}

/* Add a CSS transition delay to avoid flicker */
body,
.homePage {
  transition: background-color 0.2s ease-in-out;
}

.sagomaMaya {
  /* width: 50vw; */

  height: 100%;
  /* min-height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sagomaMaya img {
  position: relative;
  top: 0 !important;
  right: 0;
  width: 100%;
  filter: drop-shadow(30px 10px 4px rgb(0, 0, 0, 0.4));
  margin-bottom: -55px;
}

.home h5 {
  padding: 5px 10px;
  text-align: left;
  color: var(--primary);
  text-transform: uppercase;
  font-size: 1rem;
}
.home h2 {
  padding: 5px 10px;
  text-align: left;
  font-family: "Playfair Display";
  font-size: 3rem;
}
.home p {
  padding: 5px 10px;
  text-align: justify;
  color: #828282;
  font-size: 1rem;
}

.flexLeft h5 {
  text-transform: uppercase;
  font-weight: 500;
  /* color: var(--black); */
  margin-bottom: 5px;
}

.flexLeft {
  max-width: 80%;
}
.flexLeft p {
  color: var(--text);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
}

.buttonContainer a {
  margin: 10px 10px 10px 0px;
}

.buttonContainer h5 {
  font-size: 20px;
  margin: 5px !important;
  padding: 0;
  line-height: 0;
}
.home .buttonContainer h5 {
  font-size: 50px;
}
.home .buttonContainer a {
  margin: 0;
  padding: 0;
  padding-left: 5px;
}
.buttonContainer p {
  color: var(--black);
}

.buttonContainer button {
  padding-left: 5px;
  padding-right: 10px;
  background-color: transparent;
  border: solid 2px var(--black);
  border-radius: 5px;
}
.buttonContainer .portfolioButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.whiteColor {
  color: var(--secondary);
  border-color: var(--secondary);
}

.marginLeft {
  margin-left: 20px !important;
}
.marginTop {
  margin-top: 5px !important;
}
.sectors {
  /* width: 100vw; */

  display: flex;
  color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 50px; */
  padding: 25px 0 0 0;
}

.sector {
  width: calc(100vw - 50px);
  border: 3px solid #000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  color: #14310d;
}
.headerSinglePortfolio .sector {
  width: 100vw;
  border: none;
}

.textSector {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}

.imgSector {
  height: 60vh;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

/* BUBBLE */

.bubble {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  /* width: 80vw; */
  min-height: 400px;
  margin: auto;
  background-color: var(--primary);
  border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2),
    inset 15px 15px 50px rgba(0, 0, 0, 1);
  animation: morphing 15s infinite;
  overflow: hidden;
  margin: 20px 60px;
}

@media (max-width: 800px) {
  .bubble {
    width: 80%;
    margin: 20px auto;
  }
}
@media (max-width: 368px) {
  .bubble {
    min-height: 200px;
  }
}

.bubble:hover {
  animation-play-state: reverse;
}
.bubble h1 {
  color: #fff;
  font-family: Helvetica Neue, Helvetica, Verdana, sans-serif;
  font-weight: 400;
  font-size: 35px;
  line-height: 100%;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin: auto;
}
@-moz-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
  50% {
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
}
@-webkit-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
  50% {
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
}
@-o-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
  50% {
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
}
@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
  50% {
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.4),
      inset 15px 15px 50px rgba(0, 0, 0, 1);
  }
}

/* BUBBLE */

@media screen and (max-width: 700px) {
  .parallax {
    background-attachment: scroll !important;
  }
}

/* iPad */
@media screen and (max-width: 1024px) and (max-height: 768px) {
  .parallax {
    background-attachment: scroll !important;
  }
}
/* iPad Landscape */
@media screen and (max-width: 768px) and (max-height: 1024px) {
  .parallax {
    background-attachment: scroll !important;
  }
}
/* iPad Pro */
@media screen and (max-width: 1366px) and (max-height: 1024px) {
  .parallax {
    background-attachment: scroll !important;
  }
}
/* iPad Pro Landscape */
@media screen and (max-width: 1024px) and (max-height: 1366px) {
  .parallax {
    background-attachment: scroll !important;
  }
}

@media screen and (min-width: 600px) {
  .sector {
    flex-direction: row;

    margin-bottom: 25px;
  }
  .sector.reverse {
    flex-direction: row-reverse;
  }

  .imgSector {
    height: 80vh;
    width: 50vw !important;
  }
  .textSector {
    width: 50vw;
    align-items: left !important;
  }
}

.title {
  font-size: 25px;
  text-align: center;
  margin: 20px 0;
  padding: 5px 10px;
  text-align: left;
  font-family: "Playfair Display";
  color: var(--black);
}
.sector hr {
  width: 40%;
}
.description {
  /* height: 20vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 5px 0 5px;
  max-width: 700px;
  color: var(--text);
}

/* .pageContainer {
  margin-top: 65px;
} */

/* ### NAVBAR ### */

.navbar {
  height: 65px;
  width: 100vw;

  /* color: #000000 !important; */
  /* border-bottom: transparent 1px solid; */
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000 !important;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition-delay: 0s;
}

.spin {
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  /* -o-transition: rotate(-3600deg); */
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* LINKS */

.links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  margin-left: 10%;
}

.navbarLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.navbarLinks > * {
  margin: 0 5px;
  margin-top: 5px;
}

@media (min-width: 700px) {
  .navbarLinks {
    display: flex;
  }
  .menu {
    display: none;
  }
  .menuContainer {
    display: none;
  }
  body {
    overflow: scroll !important;
  }
  /* .navbar.blackBlur {
    background-color: transparent !important;
  } */
}
/* MENU */

.menu {
  padding: 20px 20px;
}

.menuSlide {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 65px);
  margin-top: 65px;
  justify-content: space-around;
  /* align-items: center; */
  text-align: left;
}
.links p {
  color: #fff;
  font-size: larger;
  text-transform: uppercase;
  padding: 5px;
}
.recipesLinks {
  text-decoration: none;
  list-style: none;
}

.formField {
  font-family: "Helvetica", "Gill Sans", "Gill Sans MT", "Calibri",
    "Trebuchet MS" !important;
}
.formField::placeholder {
  font-family: "Helvetica", "Gill Sans", "Gill Sans MT", "Calibri",
    "Trebuchet MS" !important;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.socialIcon {
  height: 55px;
  width: 55px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 10px;
}

@media screen and (max-width: 500px) {
  .socialIcon {
    height: 40px;
    width: 40px;
    margin: 3px;
  }
}
@media screen and (max-height: 500px) {
  .socialIcon {
    height: 40px;
    width: 40px;
  }
}

.line {
  width: 20px;
  height: 2px;
  background-color: #fff;
  margin-top: 5px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition-delay: 0s;
  transform: translateZ(0px);
  /* transform: scale(1) translateZ(0px); */
}
.line1.open {
  margin: 0;
  transform: rotate(45deg);
  /* transform: scale(0.8); */
}
.line2.open {
  margin: -2px 0 0 0;
  transform: rotate(-50deg);
  /* transform: scale(0.8); */
}

.logo {
  padding: 5px 10px 0 20px !important;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  /* border: #fff 5px solid; */
  padding: 0px 10px 0 20px !important;
  font-size: 25px;
  font-weight: 800;
  color: var(--secondary);
  /* color: var(--primary); */
  font-family: "Playfair Display", --font-headings, "PT Sans", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  letter-spacing: normal;
  line-height: 1;
  /* text-shadow: 1px 1px #acacac; */
}

@media (max-width: 370px) {
  .logoContainer {
    font-size: 20px;
  }
}

.logoIcon {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
  /* font-family: "futura"; */
}
.smallText {
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-top: 2px;
}

/* .resp {
  width: 100%;
} */

/* MENU */

.menuContainer {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  top: 64px;
  height: calc(100vh - 64px);
  color: #fff;
  opacity: 1;
  transform: translateY(150%);
  z-index: 2;
  transition: all 0.35s ease-in-out;
  /* transition-delay: 0.125s; */
}
.menuContainer.open {
  transform: translateY(0);
}

a {
  text-decoration: none;
  color: unset !important;
}
a:hover {
  color: unset;
}
a:visited {
  color: unset;
}

a span {
  color: #000;
}

.menuContainer .menuPortfolios a {
  transition: all 2s ease-in !important;
}

.menuContainer .menuPortfolios a {
  transition: all 2s ease-in;
}
.menuContainer .menuPortfolios {
  /* background-color: #000 !important; */
  z-index: 5 !important;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  transition: all 2s ease-in;
}
.arrowMenu {
  transition: all 0.2s ease-in;
}
.arrowMenu.open {
  transform: rotate(90deg);
}
.arrowMenu.close {
  transform: rotate(0deg);
  margin-top: -2px;
}

.menuContainer ul {
  list-style-type: none;
  margin-left: 20px;
}
.menuContainer ul.open li {
  transition: all 0.3s ease-in;
  opacity: 1;
  /* display: block; */
}
.menuContainer ul.close li {
  transition: all 0.3s ease-in;
  /* display: none; */
  opacity: 0;
}
/* FOOTER */

.pageContainer {
  z-index: 999 !important;
  margin-bottom: 650px;
}

.footerContainer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -100 !important;
  color: #fff;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  width: 100%;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-top: #000 solid 2px; */
  /* padding: 7em 0; */
}

@media (max-width: 1000px) {
  .pageContainer {
    margin-bottom: 50px;
  }
  .footerContainer {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1 !important;
    color: #fff;
  }
}
@media (max-height: 800px) {
  .pageContainer {
    margin-bottom: 50px;
  }
  .footerContainer {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1 !important;
    color: #fff;
  }
}

/* ### CONTACT ###   */

.contactContainer {
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: stretch;
  max-width: 1140px;
  box-shadow: 0px 21px 41px -13px rgb(0 0 0 / 18%);
  z-index: 0;
  margin-bottom: 50px;
}

.contact {
  width: 66.666667%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--primary);
}

@media screen and (max-width: 800px) {
  .contact {
    flex-direction: column;
  }
}

.formImg {
  /* display: none; */
  width: 50%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
/* @media screen and (max-width: 600px) {
  .formImg {
    width: 50%;
  }
  Form {
    width: 50%;
  }
} */

.infoContainer {
  width: 33.333334%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 48px 30px;
  background-color: var(--primary);
  border-radius: 0px;
}

.infoText h2,
.infoText p {
  color: #fff !important;
  margin-bottom: 10px;
  text-align: center;
}
.infoText p {
  margin-bottom: 20px;
}
.iconContainer h5 {
  color: #fff !important;
}

.iconContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: baseline;
  align-items: center;
  padding-left: 20px;
}

@media (max-width: 1200px) {
  .iconContainer {
    padding-left: 0px;
    width: auto;
  }
  .iconContainer h5 {
    display: none;
  }
}
@media (max-width: 767px) {
  .iconContainer {
    padding-left: 20px;
    width: 100%;
  }
  .iconContainer h5 {
    padding-left: 10px;
    font-size: 1rem;
    display: block;
  }
  .contactContainer {
    flex-direction: column;
    width: 90%;
  }
  .infoContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .contact {
    width: 100%;
    padding-top: 20px;
  }
}
.flexRow {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 500px) {
  .flexRow {
    flex-direction: column;
  }
  .flexRow .button {
    margin: 5px 0;
  }
}
/* Button */

.button {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 200px;
  border: 2px solid var(--black);
  border-radius: 5px;
  margin: 20px 5px;
  color: var(--black);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 15px;
  letter-spacing: 1.5px;
  overflow: hidden; */
  margin: 20px 0 !important;
  color: white !important;
}

/* ### TV ###  */
/* .singlePortfolio{

} */

/* .containerImg {
} */

.singlePortfolio .imgSinglePortfolio {
  width: 100vw;
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;

  /* filter: brightness(95%); */
}

.singlePortfolio .flex {
  color: #fff;
  max-width: 500px;
  padding: 30px 20px;
  /* backdrop-filter: blur(10px); */
}
.singlePortfolio .flex p {
  margin-top: 10px;
  text-align: center;
}
.singlePortfolio hr {
  width: 30%;
  margin: 10px;
}

.progettiContainer {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.progetto {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 25px;
  padding: 0px !important;
  border: 3px solid #000;
  border-radius: 5px;
}

@media screen and (min-width: 700px) {
  .progetto {
    flex-direction: row;
  }
  .progetto.reverse {
    flex-direction: row-reverse;
  }
}

.progetto .text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  text-align: center;
}

.progetto .text h2 {
  padding: 0 25px;
}
.progetto .text p {
  max-width: 500px !important;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  color: var(--text);
}

.frame {
  width: 100%;
  height: 100%;
  padding: 0;
  min-height: 400px;
}

.frame.img {
  object-fit: cover;
  width: 100%;
}
.frame.video {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .frame {
    min-height: 300px;
  }
}
@media screen and (min-width: 700px) {
  .frame.img {
    width: 50% !important;
  }
}
@media screen and (min-width: 900px) {
  .frame {
    min-height: 700px;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

/* MEDIA  */

@media screen and (max-width: 1400px) {
  .home h2 {
    font-size: 40px;
  }
  .home p {
    font-size: 15px;
  }
  .home h5 {
    font-size: 15px;
  }
}

@media screen and (min-width: 800px) {
  .home {
    padding: 50px;
  }
  .homePage {
    flex-direction: column-reverse;
    /* margin-top: 90px; */
    margin-bottom: 50px;
    max-width: 100vw;
    margin-bottom: 50px;
    max-width: 100vw;
    justify-content: center;
    align-items: center;
    min-width: 80vw;
  }
  .sagomaMaya img {
    width: 100%;
    min-height: 300px;
    top: 0 !important;

    /* box-shadow: 3px 20px 43px 17px rgba(0, 0, 0, 0.97); */
  }
  /* .homeFace {
    width: 100vw !important;
  } */
  .home {
    width: 50vw;
  }
}

.css-1h9z7r5-MuiBase-root-MuiTab-root.Mui-selected {
  color: var(--primary) !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: var(--primary) !important;
}

.css-ahj2mt-MuiTypography-root {
  /* line-height: unset !important; */

  line-height: 1.2 !important;
}

.appBar {
  border-radius: 5px !important;
  -webkit-flex-shrink: 1 !important;
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

/* AppBar text  */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--primary) !important;
}

/* TextArea Placeholder */
textarea {
  font-family: "Helvetica", "Gill Sans", "Gill Sans MT", "Calibri",
    "Trebuchet MS" !important;
}
textarea::placeholder {
  font-family: "Helvetica", "Gill Sans", "Gill Sans MT", "Calibri",
    "Trebuchet MS" !important;
}
textarea::-webkit-input-placeholder {
  font-family: "Helvetica", "Gill Sans", "Gill Sans MT", "Calibri",
    "Trebuchet MS" !important;
}
textarea:-moz-placeholder {
  font-family: "Helvetica", "Gill Sans", "Gill Sans MT", "Calibri",
    "Trebuchet MS" !important;
}
textarea::-moz-placeholder {
  font-family: "Helvetica", "Gill Sans", "Gill Sans MT", "Calibri",
    "Trebuchet MS" !important;
}
textarea:-ms-input-placeholder {
  font-family: "Helvetica", "Gill Sans", "Gill Sans MT", "Calibri",
    "Trebuchet MS" !important;
}

/* TIMELINE  */

.timeline {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.element {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.element .date {
  height: 100% !important;
  min-width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.element .date p {
  text-align: center;
}

.element .date .ball {
  width: 10px;
  height: 10px;
  border: #000 1px solid;
  border-radius: 50%;
  margin-left: 20px;
  padding: 9px;
  background-color: var(--primaryLight);
}
.element .date .line {
  width: 1px;
  height: 100%;
  min-height: 200px;
  margin: 0px;
  margin-top: 0;
  margin-left: -10px;
  background-color: black;
  z-index: -1;
  /* background-color: var(--primaryLight); */
}

.element .description {
  padding: 0;
}

.element .description .logo .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .element .description {
    flex-direction: column;
    padding: 10px;
    padding-left: 20px !important;
  }
  .element .description .logo {
    padding: 0 !important;
    margin: 0 !important;
  }
  .element .date .line {
    min-height: 390px;
  }
}
@media (max-width: 450px) {
  .element .date .line {
    min-height: 350px;
  }
}
@media (min-width: 601px) {
  .element .description .logo .img {
    width: 300px;
    height: 100%;
    object-fit: cover;
  }
}

/* .element .description .descriptionTxt {
} */
@media (max-width: 700px) {
  .element .description .descriptionTxt {
    min-width: 210px !important;
  }
}

.element .description .descriptionTxt h5 {
  color: var(--black);
  text-transform: uppercase;
}

.element .description .descriptionTxt h2 {
  color: var(--primary);
}

/* .element .description .descriptionTxt p {
} */

/* PORFOLIO SECTION */
.portfolioSectionContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.portfolioSectionContainer > * {
  flex: 1 0 47%;
}

@media (max-width: 600px) {
  .portfolioSectionContainer > * {
    flex: 0 0 90%;
  }
}
@media (min-width: 1000px) {
  .portfolioSectionContainer > * {
    flex: 1 0 31%;
  }
}

.portfolioSection {
  min-height: 100vh;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* border: 2px solid var(--black); */
  border: none;
  margin: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  /* box-shadow: 0px 21px 41px -13px rgb(0 0 0 / 18%); */
}

.portfolioSection .logo .img {
  min-height: 100px;
  min-width: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.portfolioSection .logo .img.wide {
  min-height: 100px;
  min-width: 250px;
}
.portfolioSection .logo .img.wideAndLong {
  min-height: 200px;
  min-width: 250px;
}
.portfolioSection h2 {
  /* color: var(--primary); */
  margin-bottom: 5px;
}

/* LANGUAGES */

.skillsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  padding-bottom: 50px;
}

.skillsContainer > * {
  flex: 0 0 33.3333%;
}

@media (max-width: 450px) {
  .skillsContainer > * {
    flex: 0 0 50%;
  }
}
@media (max-width: 350px) {
  .skillsContainer > * {
    flex: 0 0 90%;
  }
}
@media (min-width: 1000px) {
  .skillsContainer > * {
    flex: 0 0 25%;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skillLogoContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -70%;
}

.skillLogoContainer .img {
  max-width: 50%;
  max-height: 40%;
}

.skill p {
  margin-top: 10px;
}

.flex-wrapper {
  display: flex;
  margin: 10px;
  max-width: 190px;
  width: 100%;
}

@media (max-width: 600px) {
}

.single-chart {
  width: 100%;
  justify-content: center;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 1.2;
}

.circle {
  fill: none;
  stroke-width: 1;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.blue .circle {
  stroke: var(--primary);
}

.text {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.timeline ul li:nth-child(even) .imageWorkExperience {
  background-position: center left;
}
.timeline ul li:nth-child(odd) .imageWorkExperience {
  background-position: center right;
}

@media (max-width: 768px) {
  .timeline ul li:nth-child(odd) .imageWorkExperience {
    background-position: center left;
  }
}
/* WORK EXPERIENCE AND EDUCATION */
.shc {
  background: url("./components/TabSelection/assets/shc.png");
}
.gulliver {
  background: url("./components/TabSelection/assets/gulliver.png");
}
.inps {
  background: url("./components/TabSelection/assets/inps.png");
}
.solent {
  background: url("./components/TabSelection/assets/solent.png");
}
.abba {
  background: url("./components/TabSelection/assets/abba.png");
}

.saville {
  background: url("https://www.savilleassessment.com/wp-content/uploads/2024/04/SA-Logo-2-RGB.svg");
}

/* PORTFOLIO'S ICONS */

.carmine {
  background: url("./components/Portfolio/assets/carmine.png");
}
.bryan {
  background: url("./components/Portfolio/assets/bryangr.png");
}
.nimbins {
  background: url("./components/Portfolio/assets/nimbins.png");
}
.cibo {
  background: url("./components/Portfolio/assets/cibo.png");
}
.nicola {
  background: url("./components/Portfolio/assets/nicola.svg");
}
.austin {
  background: url("./components/Portfolio/assets/austin.png");
}
.meal {
  background: url("./components/Portfolio/assets/meal.png");
}

/* SCHOOL PORTFOLIO'S ICONS */
.map {
  background: url("./components/Portfolio/assets/map.gif");
}
.expensesTracker {
  background: url("./components/Portfolio/assets/expensesTracker.gif");
}
.eshop {
  background: url("./components/Portfolio/assets/eshop.gif");
}

.imageWorkExperience {
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 20px;
}
.css-cjctlb-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--primaryLight);
}

.css-1ujnqem-MuiTabs-root {
  overflow: hidden;
  padding: 0 3px !important;
}

.mb-5 {
  margin-bottom: 30px;
}

.w-full {
  width: 100vw;
}

.h-screen {
  min-height: 100vh;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.poweredBy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.poweredBy span {
  color: var(--primary) !important;
  margin: 20px 0;
}

.uppercase {
  text-transform: uppercase;
}

.pageContainer {
  background-color: #fff;
  transition: all 0.25s;
}

.social-icon {
  color: var(--primary);
}

.project-languages {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.project-languages-container {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 12px;
  padding: 10px;
}

.project-language {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
}
