.skillLogoContainer .img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* SKILLS */

.azure {
  background: url(../assets/azure.png);
}
.mongo {
  background: url(../assets/mongodb.png);
}
.firebase {
  background: url(../assets/firebase.png);
}
.sanity {
  background: url(../assets/sanity.png);
}
.mysql {
  background: url(../assets/mySQL.png);
}
.maria {
  background: url(../assets/mariaDB.png);
}
.phpMyAdmin {
  background: url(../assets/phpMyAdmin.png);
}
.illustrator {
  background: url(../assets/illustrator.png);
}
.photoshop {
  background: url(../assets/photoshop.png);
}
.xd {
  background: url(../assets/xd.png);
}
.figma {
  background: url(../assets/figma.png);
}
.netlify {
  background: url(../assets/netlify.png);
}
.heroku {
  background: url(../assets/heroku.png);
}
.github {
  background: url(../assets/github.png);
}
.git {
  background: url(../assets/git.png);
}
.npm {
  background: url(../assets/npm.png);
}
.material {
  background: url(../assets/materialUI.png);
}
.gsap {
  background: url(../assets/gsap.png);
}
.framer {
  background: url(../assets/framer.png);
}
.stripe {
  background: url(../assets/stripe.png);
}
.hbs {
  background: url(../assets/handlebars.svg);
}
.mongoose {
  background: url(../assets/mongoose.png);
}
.sass {
  background: url(../assets/sass.png);
}
.swiper {
  background: url(../assets/swiper.svg);
}
.three {
  background: url(../assets/three.png);
}
.vercel {
  background: url(../assets/vercel.svg);
}
.bootstrap {
  background: url(../assets/bootstrap.png);
}
.passport {
  background: url(../assets/passport.png);
}
.tailwind {
  background: url(../assets/tailwind.png);
}
.sendgrid {
  background: url(../assets/sendgrid.png);
}
.redux {
  background: url(../assets/redux.svg);
}
.paypal {
  background: url(../assets/paypal.png);
}
.jwt {
  background: url(../assets/jwt.svg);
}
.strapi {
  background: url(../assets/strapi.png);
}
